import { render, staticRenderFns } from "./About.vue?vue&type=template&id=5c9a3790&scoped=true&lang=pug&"
import script from "./About.vue?vue&type=script&lang=js&"
export * from "./About.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5c9a3790",
  "6ea7db8c"
  
)

export default component.exports