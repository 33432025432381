import { render, staticRenderFns } from "./ExternalAnchor.vue?vue&type=template&id=58efb789&lang=pug&"
import script from "./ExternalAnchor.vue?vue&type=script&lang=js&"
export * from "./ExternalAnchor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "77aff919"
  
)

export default component.exports